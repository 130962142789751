import React from 'react'

    const PopUpFlightInfo = (props) => {

        const { flight, ClearFlight } = props

        return (

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', width: '100%', height: '150px', zIndex: 999, bottom: '20px'}}>

                <div className="flightInfoContent" style={{position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '700px', backgroundColor: 'rgba(0, 35, 95, 0.7)', color: "#fff", borderRadius: '6px'}}>

                    <div style={{position: 'absolute', right: '10px', top: '10px', backgroundColor: 'rgba(0, 35, 95, 1)', borderRadius: '50%', padding: '5px 10px', cursor: 'pointer'}} onClick={() => ClearFlight()}>X</div>

                    <div style={{width: '50%', padding: '10px', backgroundColor: 'rgba(0, 35, 95, 1)', borderBottom: '2px solid #fff', borderRadius: '0 0 6px 6px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                        <strong>{flight.callsign}</strong> - {flight.name_pilot}

                    </div>

                    <div className="columns" style={{padding: '5px 25px', display: 'flex', justifyContent: 'space-between', height: '100px'}}>

                        <div className="shotColumn" style={{maxWidth: '150px', width: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                            <div style={{fontSize: '31px', fontWeight: 'bold'}}>{flight.dep_iata}</div>

                            <span style={{textAlign: 'center', fontSize: '10px'}}>{flight.dep_municipality}, {flight.dep_iso_country}</span>

                        </div>

                        <div className="bigColumn" style={{height: '100px', display: 'flex', alignItems: 'center', width: '400px', justifyContent: 'center'}}>


                            <div className="flightPath" style={{width: '100%', backgroundColor: '#fafafa', height: '2px', position: 'relative'}}>

                                <div style={{position: 'absolute', top: '-30px', left: `${flight.perc_completed}%`, transition: 'ease all .4s'}}>
                                    
                                    <svg
                                        version="1.1"
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{width: '65px', transform: 'rotate(90deg)', height: '60px'}}
                                    >
                                        <path d="m 32,3.2 0.1,0 0.4,0.2 0.7,1 0.8,1.9 0.6,2.5 0.2,2.5 0.1,2.7 0,9.3 6.5,4.6 -0.3,-0.8 -0.2,0.3 -0.2,-0.3 -0.2,0.1 -0.1,-0.3 -0.2,-0.9 -0.1,-1.3 0,-1.6 0.1,-1.2 0.1,-0.4 0.2,-0.2 3.2,0 0.2,0.2 0.1,0.4 0.2,1.2 0,1.6 -0.1,1.3 -0.4,1.4 -0.1,-0.3 -0.1,0.3 -0.1,-0.3 -0.2,0.3 -0.2,-0.3 -0.4,1.6 17.5,12.3 0.6,0.6 0.4,0.6 1.2,2 0,1 -1.6,-1.4 -0.7,-0.5 -0.9,-0.5 -7.5,-3.2 -0.1,0.8 -0.2,0.3 -0.3,-0.6 -0.1,-0.8 -3.8,-1.6 -0.1,0.8 -0.2,0.3 -0.3,-0.7 -0.1,-0.7 -3.2,-1.4 -2,0 -0.1,0.7 -0.2,0.4 -0.2,-0.4 -0.1,-0.7 -5.7,0 0,9.1 -0.1,1.8 -0.1,1.6 -0.5,3.3 0,0.4 0.2,0.4 7,6.4 0.2,0.3 0.1,0.3 0.4,1.3 -9.2,-3.4 -0.7,2.9 -0.4,0 -0.7,-2.9 -9.2,3.4 0.4,-1.3 0.1,-0.3 0.2,-0.3 7,-6.4 0.2,-0.4 0,-0.4 -0.5,-3.3 -0.1,-1.7 -0.1,-1.7 0,-9.1 -5.7,0 -0.1,0.7 -0.2,0.5 -0.2,-0.5 -0.1,-0.7 -2,0 -3.3,1.4 -0.1,0.6 -0.3,0.6 -0.2,-0.3 -0.1,-0.7 -3.6,1.7 -0.1,0.6 -0.3,0.6 -0.2,-0.3 -0.1,-0.6 -7.6,3.2 -0.9,0.5 -0.7,0.5 -1.6,1.4 0,-1 1.2,-2 0.4,-0.6 0.6,-0.6 17.5,-12.3 -0.4,-1.6 -0.2,0.3 -0.2,-0.3 -0.1,0.3 -0.1,-0.3 -0.1,0.3 -0.3,-1.4 -0.1,-1.3 0,-1.6 0.1,-1.2 0.1,-0.4 0.2,-0.2 3.2,0 0.2,0.2 0.1,0.4 0.1,1.2 0,1.6 -0.1,1.3 -0.2,0.9 -0.1,0.3 -0.2,-0.1 -0.2,0.3 -0.2,-0.3 -0.3,0.8 6.5,-4.6 0,-9.3 0.1,-2.7 0.2,-2.5 0.6,-2.5 0.8,-1.9 0.7,-1 0.4,-0.2 z" fill="#fff"></path>
                                    </svg>

                                    {/*<span style={{position: 'absolute', left: '-10px'}}>{`${flight.perc_completed}%`}</span>*/}

                                </div>

                            </div>

                        </div>

                        <div className="shotColumn" style={{maxWidth: '150px', width: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                            <div style={{fontSize: '31px', fontWeight: 'bold'}}>{flight.arr_iata}</div>

                            <span style={{textAlign: 'center', fontSize: '10px'}}>{flight.arr_municipality}, {flight.arr_iso_country}</span>

                        </div>

                    </div>

                </div>

            </div>

        )

    }

export default PopUpFlightInfo;